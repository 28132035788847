import React from 'react';
import Main from 'layouts/Main';
import Box from '@mui/material/Box';

import {
  BigTextHeader,
  CurrentPageNavText,
  BannerWithButton,
  Feedback,
  Blog,
  ImagePlusText,
  ExplainInfoPlusImage
} from 'customComponents';

import OurStory from 'customComponents/ImagePlusText/OurStory.png';
import OurMission from 'customComponents/ImagePlusText/OurMission.png';
import whyUsOne from 'customComponents/ExplainInfoPlusImage/whyUsOne.png';
import whyUsTwo from 'customComponents/ExplainInfoPlusImage/whyUsTwo.png';

const About = () => {
  const ourStoryTitleHTML = (
    <div>
      Our Story
    </div>
  );
  const ourStoryMainHTML = (
    <div>
      At Sonnul, we believe that there's no better way to reach your ideal customers than with a website. We can build you an e-commerce site or a simple blog—whatever it is you need to get your business online and in front of new customers.
      <br />
      <br />
      We started this company because we feel passionately about building top-notch websites for local businesses at reasonable prices. We do this by offering world-class customer service and giving our clients the platform they need to succeed.
      <br />
      <br />
      We know that small businesses are the backbone of our economy, so we're dedicated to helping them reach their goals by providing them with a professional website that will showcase their brand and values in the best possible way.
    </div>
  );

  const ourMissionTitleHTML = (
    <div>
      Our Mission
    </div>
  );
  const ourMissionMainHTML = (
    <div>
      Our Core Values consist of two key points: innovation and quality.
      <br />
      <br />
      Innovation is at the heart of our company—we're constantly thinking up new ways to improve on what we're already doing. We believe that in order to create a great product or service, you have to be willing to try something new and take risks. In fact, the best ideas come from mixing together two or more different things that might not normally go together!
      <br />
      <br />
      Quality is one of our highest priorities because we know that it's important for our customers to know they can rely on us for quality products and services. If someone buys from Sonnul, they can be confident in knowing that they're getting a product or service that has been carefully considered, tested thoroughly before release onto the market and will always be backed by a guarantee of quality assurance for their satisfaction.
    </div>
  );

  const whyUsTitleHTML = (
    <div>
      Why Us?
    </div>
  );
  const whyUsFirstTextHTML = (
    <div>
      Sonnul is a local company, with no office.
      <br />
      We're based in Yuba City, California, and we're one phone call away from you.
      <br />
      That's right—we offer free in-person meet-ups for any customer who wants to discuss their needs in person.
      <br />
      And the coffee will be on us too!
    </div>
  );
  const whyUsSecondTextHTML = (
    <div>
      At Sonnul, we're a team of smart engineers and creative digital marketers who love to think outside the box.
      <br />
      We're all about making your life easier, so you can focus on what matters: your business.
    </div>
  );

  return (
    <Main colorInvert={true}>
      <BigTextHeader
        headerText={"About Sonnul"}
        subheaderText={""}
        showButton={false}
      />
      <CurrentPageNavText navLocText={"Home > About Us"} />

      <ExplainInfoPlusImage
        titleVariant={"h3"}
        title={whyUsTitleHTML}
        firstText={whyUsFirstTextHTML}
        secondText={whyUsSecondTextHTML}
        showButton={true}
        imageOne={whyUsOne}
        imageOneSizeLarge={300}
        imageOneSizeSmall={350}
        imageTwo={whyUsTwo}
        imageTwoSizeLarge={555}
        imageTwoSizeSmall={350}
      />

      <Box bgcolor={"background.paper2"} paddingY={6}>
        <ImagePlusText
          titleVariant={"h3"}
          title={ourStoryTitleHTML}
          mainText={ourStoryMainHTML}
          imageSrc={OurStory}
          showButton={false}
          smallScreenFlexDirection={"column-reverse"}
          imageSizeLarge={500}
          imageSizeSmall={350}
          yPaddingLarge={0}
          yPaddingSmall={6}
          mdSpacing={12}
          smSpacing={4}
        />

        <ImagePlusText
          titleVariant={"h3"}
          title={ourMissionTitleHTML}
          mainText={ourMissionMainHTML}
          imageSrc={OurMission}
          showButton={false}
          smallScreenFlexDirection={"column-reverse"}
          fullScreenFlexDirection={"row"}
          imageSizeLarge={500}
          imageSizeSmall={350}
          yPaddingLarge={10}
          yPaddingSmall={6}
          gridSpacing={12}
        />
      </Box>

      <Box paddingTop={8}>
        <Feedback />
      </Box>

      <Blog />

      <BannerWithButton
        text1="Schedule a quick call with our team."
        text2="We're excited to see what you have in mind!"
        buttonText="Schedule Call"
        buttonAltText="Free Consultation"
        linkUrl="/schedule-call"
      />
    </Main>
  );
};

export default About;
